<!-- Topbar -->
<nav
  class="
    navbar navbar-expand navbar-light
    bg-white
    topbar
    mb-4
    static-top
    shadow
  "
>
  <!-- Sidebar Toggle (Topbar) -->
  <button id="sidebarToggleTop" class="btn btn-link d-none rounded-circle mr-3">
    <i class="fa fa-bars"></i>
  </button>

  <div class="logo-container">
    <i class="fas fa-chart-line"></i>
    <div class="sidebar-brand-text mx-1">Perfect-folio <sup>2</sup></div>
  </div>

  <!-- Topbar Search -->
  <!-- <form
    class="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
  >
    <div class="input-group">
      <input
        type="text"
        class="form-control bg-light border-0 small"
        placeholder="Search for..."
        aria-label="Search"
        aria-describedby="basic-addon2"
      />
      <div class="input-group-append">
        <button class="btn btn-primary" type="button">
          <i class="fas fa-search fa-sm"></i>
        </button>
      </div>
    </div>

  </form> -->

  <!-- Topbar Navbar -->
  <ul class="navbar-nav ml-auto">
    <!-- Nav Item - User Information -->
    <li *ngIf="user$ | async" class="nav-item dropdown no-arrow">
      <a
        class="nav-link dropdown-toggle"
        href="#"
        id="userDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <!-- <span class="mr-2 d-inline text-gray-900 small">My User</span> -->
        <!-- <i class="fas fa-chart-line"></i> -->
        <i class="fas fa-user text-gray-900"></i>
        <!-- <img
          class="img-profile rounded-circle"
          src="/src/assets/img/undraw_profile.svg"
        /> -->
      </a>
      <!-- Dropdown - User Information -->
      <div
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="userDropdown"
      >
        <p class="dropdown-item">
          <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-600"></i>
          Profile (coming soon)
        </p>
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item"
          href="#"
          data-toggle="modal"
          data-target="#logoutModal"
        >
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-600"></i>
          Logout
        </a>
      </div>
    </li>
  </ul>
</nav>
<!-- End of Topbar -->

<!-- Logout Modal-->
<div
  class="modal fade"
  id="logoutModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Select "Logout" below if you are ready to end your current session.
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">
          Cancel
        </button>
        <button
          (click)="logout()"
          type="button"
          data-dismiss="modal"
          class="btn btn-primary"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
</div>
