<div class="row">
  <ng-container *ngFor="let security of controls">
    <ng-container [formGroup]="security">
      <mat-form-field floatLabel="never" class="col-3 col-lg-2 percent-input">
        <input
          [readonly]="!(security && isEditMode)"
          type="number"
          min="1"
          max="99"
          matInput
          (focus)="clearControl(security.controls.percentage)"
          [formControlName]="'percentage'"
          class="percent-input"
        />
        <mat-icon matSuffix><i class="fas fa-percent"></i></mat-icon>
      </mat-form-field>

      <div class="prefix col-4 col-lg-2">
        <span>{{ security.controls?.symbol?.value }}</span>
      </div>

      <mat-form-field floatLabel="never" class="col-10 col-lg-6">
        <input
          [readonly]="true"
          type="text"
          matInput
          value="{{ security.controls?.name?.value }}"
        />
        <mat-icon matSuffix>
          {{
            security.controls?.price?.value
              | currency: security.controls?.currency?.value
          }}
        </mat-icon>
      </mat-form-field>

      <div class="col-2 col-lg-2 suffix">
        <p *ngIf="!isEditMode; else clearButton" class="lead">
          {{ security.controls?.count?.value }}
        </p>
        <ng-template #clearButton>
          <button
            (click)="securityDeleted.emit(security.getRawValue())"
            class="btn text-gray-800 delete"
            type="button"
          >
            <i class="fas fa-times"></i>
          </button>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</div>
