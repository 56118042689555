import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ChartType, ChartOptions, controllers } from 'chart.js';
import {
  SingleDataSet,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
} from 'ng2-charts';
import { BreakpointObserver } from '@angular/cdk/layout';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() securities: FormArray;
  @Input() cash: number;

  public breakpoint$;

  public get securityControls(): any {
    return (this.securities.controls as FormGroup[]).map(
      (control) => control.controls
    );
  }

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
  };
  public pieChartLabels: Label[];
  public pieChartData: SingleDataSet;
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;

  constructor(private _observer: BreakpointObserver) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit() {
    this.breakpoint$ = this._observer.observe('(min-width: 576px)');

    this.pieChartLabels = this.securityControls
      .map(
        (control) => `${control?.symbol.value} ${control?.percentage.value}% `
      )
      .concat(`Cash ${Math.round(this.cash)}%`);

    this.pieChartData = this.securityControls
      .map((control) => control?.percentage.value)
      .concat(Math.round(this.cash));
  }
}
