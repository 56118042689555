<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">Portfolios</h1>
    <button
      (click)="onAddPortfolio()"
      class="d-inline-block btn btn-primary shadow-sm"
      type="button"
    >
      <i class="fas fa-plus fa-sm text-white-50"></i> New
    </button>
  </div>

  <!-- Content Row -->
  <!-- <form [formGroup]="form"> -->
  <ng-container *ngIf="!isLoading else spinner">
    <ng-container *ngIf="portfolioValues.length > 0; else noPortfolios">
      <div *ngFor="let portfolio of portfolioValues">
        <app-portfolio
          [portfolio]="portfolios[portfolio.key]"
          (portfolioFormReady)="portfolioFormInitialized(portfolio.key, $event)"
          (balance)="onBalance($event)"
          (edit)="onEdit($event)"
          (delete)="onDelete($event)"
          (confirmDelete)="onConfirmDelete()"
        ></app-portfolio>
      </div>
    </ng-container>
    <ng-template #noPortfolios>
      <div class="card shadow mb-4">
        <div class="card-body">
          <i class="fa fa-exclamation-triangle text-warning"></i>
          Add a Portfolio to get started
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #spinner>
    <div class="d-flex justify-content-center w-100 h-100">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
