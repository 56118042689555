<form [formGroup]="form">
  <!-- Area Chart -->
  <div class="card shadow border-0 mb-4">
    <!-- Card Body -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-3 d-flex justify-content-between">
          <div>
            <p *ngIf="!isEditMode" class="h5 font-weight-thin text-dark">
              {{ portfolio?.name | titlecase }}
            </p>
            <input
              *ngIf="portfolio && isEditMode"
              type="text"
              class="form-control portfolio-name w-50"
              (focus)="clearControl('name')"
              [formControlName]="'name'"
            />
            <p
              *ngIf="
                isEditMode &&
                form.controls.name.touched &&
                form.controls.name.invalid &&
                (form.controls.name.errors?.required ||
                  form.controls.name.errors?.alphanumeric)
              "
              class="text-danger m-0"
            >
              Invalid Entry, please enter an alphanumeric value.
            </p>
          </div>
          <div class="dropdown no-arrow">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-ellipsis-v fa-md fa-fw text-dark"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
              aria-labelledby="dropdownMenuLink"
            >
              <div class="dropdown-header">Actions:</div>
              <button (click)="onEdit()" class="dropdown-item" type="button">
                Edit
              </button>
              <button
                type="button"
                class="dropdown-item"
                data-toggle="modal"
                data-target="#deleteModal"
                (click)="onDelete(portfolio.key)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-12">
              <p *ngIf="!isEditMode" class="h6 font-weight-thin text-gray-800">
                {{ portfolio?.investment | currency: portfolio?.currency }}
              </p>

              <div class="input-group investment-input-group mb-3">
                <input
                  *ngIf="portfolio && isEditMode"
                  type="number"
                  class="form-control w-10"
                  name="investment"
                  (focus)="clearControl('investment')"
                  [formControlName]="'investment'"
                />
                <select
                  *ngIf="portfolio && isEditMode"
                  [formControlName]="'currency'"
                  class="custom-select"
                  id="currency-select"
                >
                  <option [value]="Currency.USD">{{ Currency.USD }}</option>
                  <option [value]="Currency.CAD">{{ Currency.CAD }}</option>
                </select>
                <p
                  *ngIf="
                    isEditMode &&
                    form.controls.investment.touched &&
                    form.controls.investment.invalid &&
                    (form.controls.investment.errors?.required ||
                      form.controls.investment.errors?.numeric)
                  "
                  class="text-danger"
                >
                  Invalid Entry, please enter an numeric value.
                </p>
                <p
                  *ngIf="
                    isEditMode &&
                    form.controls.currency.touched &&
                    form.controls.currency.invalid &&
                    (form.controls.currency.errors?.currency ||
                      form.controls.currency.errors?.numeric)
                  "
                  class="text-danger"
                >
                  Invalid currency.
                </p>
              </div>
            </div>

            <div class="col-12">
              <p
                *ngIf="!isEditMode"
                class="h6 mb-3 font-weight-thin text-gray-800"
              >
                {{ portfolio?.cash | currency: portfolio?.currency }}
                <!-- {{ portfolio?.cash / portfolio?.investment || 1 | percent }} -->
                Cash
              </p>
            </div>

            <div class="mt-3 col-12">
              <app-securities
                [isEditMode]="isEditMode"
                [securities]="securities"
                (securityDeleted)="onSecurityDeleted($event)"
              ></app-securities>
            </div>

            <div class="col-10 col-lg-9 offset-0 offset-lg-2">
              <app-search
                class="app-search"
                *ngIf="isEditMode"
                (securitySelected)="onSecuritySelected($event)"
              ></app-search>
            </div>

            <div class="col-12">
              <p
                *ngIf="
                  isEditMode &&
                  form.controls.securities &&
                  form.controls.securities &&
                  form.controls.securities.errors?.percentage
                "
                class="text-danger mt-1"
              >
                Sum of allocations can't be more than 100%
              </p>
            </div>

            <div class="col-12 d-flex justify-content-start">
              <button
                *ngIf="isEditMode"
                (click)="onEdited()"
                type="button"
                class="mt-5 mr-2 btn btn-success shadow-sm"
              >
                <i class="fas fa-check fa-sm text-white-50"></i> Done
              </button>

              <button
                *ngIf="isEditMode"
                (click)="onCancel()"
                type="button"
                class="mt-5 mr-2 btn btn-outline-secondary shadow-sm"
              >
                <i class="fas fa-times fa-sm"></i> Cancel
              </button>

              <button
                *ngIf="!isEditMode"
                (click)="balance.emit(portfolio.key)"
                type="button"
                class="mt-5 mr-2 btn btn-primary shadow-sm"
              >
                <i class="fas fa-balance-scale fa-sm text-white-50"></i>
                Balance
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-2">
          <app-chart
            [securities]="securities"
            [cash]="(portfolio?.cash / portfolio?.investment || 1) * 100"
          ></app-chart>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Delete Modal-->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
        <button
          class="close"
          type="button"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        Select "Delete" below if you are ready to delete this portfolio.
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal">
          Cancel
        </button>
        <button
          (click)="onConfirmDelete()"
          type="button"
          data-dismiss="modal"
          class="btn btn-danger"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
</div>
