<div>
  <div class="chart">
    <canvas
      baseChart
      [data]="pieChartData"
      [labels]="pieChartLabels"
      [chartType]="pieChartType"
      [options]="pieChartOptions"
      [plugins]="[]"
      [legend]="(breakpoint$ | async)['matches']"
    >
    </canvas>
  </div>
</div>
