<!-- Search form -->
<!-- Topbar Search -->
<!-- <div class="col-12"> -->
<mat-form-field class="w-100" floatLabel="never">
  <!-- <mat-label>Search</mat-label> -->
  <input
    type="text"
    placeholder="Search for stock..."
    aria-label="Number"
    matInput
    [formControl]="myControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="formatter"
    (optionSelected)="optionSelected($event)"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.symbol + " - " + option.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<!-- </div> -->
