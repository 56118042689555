<!-- Page Wrapper -->
<div id="wrapper" class="wrapper">
  <!-- <app-sidebar></app-sidebar> -->

  <!-- Content Wrapper -->
  <div id="content-wrapper" class="d-flex flex-column">
    <!-- Main Content -->
    <div id="content">
      <app-header></app-header>

      <!-- Begin Page Content -->
      <router-outlet></router-outlet>
      <!-- /.container-fluid -->
    </div>
    <!-- End of Main Content -->

    <app-footer></app-footer>
  </div>
  <!-- End of Content Wrapper -->
</div>
<!-- End of Page Wrapper -->

<!-- Scroll to Top Button-->
<a class="scroll-to-top rounded" href="#page-top">
  <i class="fas fa-angle-up"></i>
</a>
